import { store } from "../Store";

//const host = (process.env.NODE_ENV === 'production') ? 'https://api.clan28.cl' : 'http://localhost:8000'
const host = 'https://api.clan28.cl'


export function get(path) {
  return request(path, 'GET')
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function del(path) {
  return request(path, 'DELETE')
}

function request(path, method, body) {
  const { session } = store.getState()

  return fetch(host + path, {
    method,
    headers: {
      'API-TOKEN': session.token
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(res => res.json())
}