import { fetchComunas, fetchProductos, fetchSucursales, isSuccess, login, logout, getSession } from "../api";
import { CARRITO_ADD, CARRITO_DELETE, CARRITO_REMOVE, ELIMINAR_SESSION,
  FIJAR_COMUNAS, FIJAR_USUARIO,
  FIJAR_COMUNA_ACTIVA,
   FIJAR_PRODUCTOS, FIJAR_SESSION, FIJAR_SUCURSALES, FIJAR_ERROR } from "./Types";


export const initial_setup = () => (dispatch, getState) => {
  const p1 = fetchSucursales().then(fijar_sucursales).then(dispatch)
  const p2 = fetchComunas().then(fijar_comunas).then(dispatch)
  const p3 = getSession().then(fijar_session).then(dispatch)

  return Promise.all([p1, p2, p3]).then(getState).then(fetch_productos).then(dispatch)
}

async function fetch_productos({ session, sucursales }) {
  /*const id_comuna = session.id_comuna
  if (!id_comuna) {
    return fijar_error('Comuna no existe')
  }

  const sucursal = sucursales.find(v => v.comunas.some(k => k === id_comuna))
  if (!sucursal) {
    console.log('No existe sucursal para la comuna', id_comuna)
    return fijar_comuna_activa(null)
  }

  console.log('fetch_productos', sucursal)
  const res = await fetchProductos(sucursal.id)*/
  const res = await fetchProductos(2)

  if (isSuccess(res)) {
    return {
      type: FIJAR_PRODUCTOS,
      productos: res.data
    }
  }
}

export function fijar_comuna_activa(id_comuna) {
  return {
    type: FIJAR_COMUNA_ACTIVA,
    id_comuna
  }
}

export function crear_session(email, codigo) {
  return (dispatch) => login(email, codigo).then(res => {
    if (isSuccess(res)) {
      dispatch({
        type: FIJAR_SESSION,
        email,
        token: res.data
      })
    }
    return res
  })
}

export function destruir_session() {
  return (dispatch) => logout().finally(() => dispatch({
    type: ELIMINAR_SESSION
  }))
}

export function carrito_agregar(_id) {
  return {
    type: CARRITO_ADD,
    _id
  }
}

export function carrito_quitar(_id) {
  return {
    type: CARRITO_REMOVE,
    _id
  }
}

export function carrito_eliminar(_id) {
  return {
    type: CARRITO_DELETE,
    _id
  }
}



function fijar_sucursales(res) {
  const data = res.data
  if (isSuccess(res)) {
    return {
      type: FIJAR_SUCURSALES,
      sucursales: data.sort(sortSucursales)
    }
  }
  return fijar_error(data)
}

function sortSucursales(a, b) {
  return a.id - b.id
}

function fijar_comunas(res) {
  const data = res.data
  if (isSuccess(res)) {
    return {
      type: FIJAR_COMUNAS,
      comunas: data
    }
  }
  return fijar_error(data)
}

function fijar_session(res) {
  const data = res.data
  if (isSuccess(res)) {
    return {
      type: FIJAR_USUARIO,
      usuario: data
    }
  }
  return fijar_error(data)
}

function fijar_error(e) {
  return {
    type: FIJAR_ERROR,
    message: e.message ? e.message : e
  }
}
