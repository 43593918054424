import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { SUCURSALES, ROOT, MENU, PROMOS, LOGIN, SHOPPING_CART } from "../utils/Routes";
import { headerStyle, headerScrolling, hiddenBarMenuConfig } from "../Init";
import { is_empty } from '../utils';
import { destruir_session } from "../actions";
import { bindActionCreators } from 'redux';
import { autorizado_from_state, carrito_from_state } from '../reducers';


class Header extends Component {
  componentDidMount() {
    headerStyle()
    headerScrolling()
    hiddenBarMenuConfig()
  }

  render() {
    const { cantidad, email, autorizado } = this.props
    const pathname = this.props.history.location.pathname

    return (
      <div>
        <header className="main-header header-style-two">
          <div className="main-box">
            <div className="auto-container">
              <div className="outer-container clearfix">
                <div className="logo-box">
                  <div className="logo"><Link to={ROOT}><img src="images/logo.png" alt="Artica" /></Link></div>
                </div>
                <div className="nav-outer clearfix">
                  <nav className="main-menu">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div className="navbar-collapse collapse clearfix">
                      <ul className="navigation clearfix">
                        <MenuItem to={ROOT} current={pathname} label="Home"  onClick={resetScroll} />
                        {/*<li><a href="#quienessomos">Quienes Somos</a></li>*/}
                        <MenuItem to={MENU} current={pathname} label="Menu" onClick={resetScroll} />
                        <MenuItem to={PROMOS} current={pathname} label="Promos" onClick={resetScroll} />
  
                        {/*<li className="cart-btn"><Link to={SHOPPING_CART}><span className="icon flaticon-bag"></span><span className="cs-count">{cantidad}</span></Link></li>*/}
                        {/*autorizado &&<PerfilButton email={email} onClick={this.props.destruir_session} />*/}
                      </ul>
                    </div>
                  </nav>
                  <div className="btn-outer">
                    <MenuItem to={SUCURSALES} current={pathname} label="Contacto" onClick={resetScroll} />
                  </div>
                  {/*!autorizado && <IngresarButton path={pathname} onClick={this.props.destruir_session} />*/}
                </div>
                <div className="nav-toggler">
                  <button className="hidden-bar-opener"><span className="icon fa fa-bars"></span></button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="hidden-bar right-align">
          <div className="hidden-bar-closer">
            <button className="btn"><i className="fa fa-close"></i></button>
          </div>
          <div className="hidden-bar-wrapper">
            <div className="logo text-center">
              <Link to={ROOT}><img src="images/logo.png" alt="Kamisushi" /></Link>
            </div>
            <div className="side-menu">
              <ul className="navigation clearfix">
                <MenuItem to={ROOT} current={pathname} label="Home" onClick={toggle} />
                {/*<li><a href="#">Quienes Somos</a></li>*/}
                <MenuItem to={MENU} current={pathname} label="Menu" onClick={toggle} />
                <MenuItem to={PROMOS} current={pathname} label="Promos" onClick={toggle} />
                <MenuItem to={SUCURSALES} current={pathname} label="Contacto" onClick={toggle} />
                {/*<li className="cart-btn"><a href="shopping-cart.html"><span className="icon flaticon-bag"></span><span className="cs-count">3</span></a></li>*/}
                {/*!autorizado && <MenuItem to={LOGIN} current={pathname} label="Ingresar" className="reserv-box-btn" onClick={toggle} />*/}
                {/*autorizado && <PerfilButton email={email} />*/}
              </ul>
            </div>
            <div className="social-icons">
              <ul>
                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const IngresarButton = ({ path }) => (
  <div className="btn-outer">
    <MenuItem to={LOGIN} current={path} label="Ingresar" className="reserv-box-btn" />
  </div>
)

const PerfilButton = ({ email, onClick }) => (
  <li><a onClick={() => onClick()}>{email}</a></li>
)


const MenuItem = ({ to, label, current, className, onClick }) => (
  <li className={(className ? className : '') + (current === to && "current")}>
    <Link to={to} onClick={onClick}>{label}</Link>
  </li>
)

function toggle() {
  window.$('.hidden-bar').removeClass('visible-sidebar')
  resetScroll()
}

function resetScroll() {
  window.scrollTo(0, 0)
}

const mapStateToProps = (state) => ({
  cantidad: carrito_from_state(state).reduce((t, v) => t + v.cantidad, 0),
  email: state.session.email,
  autorizado: autorizado_from_state(state)
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  destruir_session
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)