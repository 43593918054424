import React, { Component } from 'react'
import { connect } from 'react-redux'


class Footer extends Component {
  render() {
    let sucursales = this.props.sucursales
    return (
      <footer className="main-footer">
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">

              <div className="big-column col-md-9 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  <div className="footer-column col-md-2 col-sm-4 col-xs-12">
                    <div className="footer-widget about-widget">
                      <div className="footer-logo"><figure><img src="images/logo2.jpg" width="150" height="150"  alt="" /></figure></div>
                    </div>
                  </div>
                  <div className="footer-column col-md-10 col-sm-8 col-xs-12">
                    <div className="footer-widget">
                      <h2>Locales</h2>
                      <div className="row text-white">
                        {sucursales.map(sucursal => (
                          <div key={sucursal.id} className="col-md-6 col-xs-12">
                            <span className="col-xs-2 icon flaticon-arrows-6"></span>
                            <div className="col-xs-10">
                              <label className="text-white">{sucursal.nombre}</label>
                              <div>{sucursal.direccion}</div>
                              <div>
                                <div>Cel: {sucursal.telefonos.length > 0 ? sucursal.telefonos[0] : 'xxx xxx xxx'}</div>
                                <div>Tel: {sucursal.telefonos.length > 1 ? sucursal.telefonos[1] : 'xxx xxx xxx'}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="big-column col-md-3 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  <div className="footer-column">
                    <div className="footer-widget opening-widget">
                      <h2>Horarios de atención</h2>
                      <div className="widget-content">
                        <ul>
                          <li className="clearfix"><div className="day pull-left">Lunes a Viernes</div><span className="dots">................</span><div className="time pull-right">13:15 pm - 01:00 am</div></li>
                          <li className="clearfix"><div className="day pull-left">Sabados</div><span className="dots">................</span><div className="time pull-right">13:15 am - 01:00 am</div></li>
                          <li className="clearfix"><div className="day pull-left">Domingos</div><span className="dots">................</span><div className="time pull-right">13:15 am - 01:00 am</div></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container">
            <div className="copyright-text">Copyright &copy; {new Date().getFullYear()}. Todos los derechos reservados</div>
          </div>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = ({ sucursales }) => ({ sucursales })

export default connect(mapStateToProps)(Footer)