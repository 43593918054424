import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import CartItem from '../components/CartItem'
import { carrito_from_state, comuna_from_state } from '../reducers'
import { SELECCIONAR_COMUNA } from '../utils/Routes'


function sortProducto(a, b) {
  return a.index - b.index
}


class Checkout extends Component {
  render() {
    const { carrito, comuna } = this.props

    if (!comuna) {
      return <Redirect to={SELECCIONAR_COMUNA} />
    }

    return (
      <div className="checkout-page">
        <div className="auto-container">
          <section className="cart-section">
            <div className="cart-options clearfix">
              
              <div className="billing-details">
                <div className="shop-form default-form">
                  <form method="post" action="checkout.html">
                    <div className="row clearfix">
                      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div className="place-order">
                          <div className="default-title"><h2>Metodo de envio</h2></div>
                          <div className="payment-options">
                            <ul>
                              <li>
                                <div className="radio-option">
                                  <input type="radio" name="type-group" id="payment-1" checked />
                                  <label for="payment-1">Retiro en local</label>
                                </div>
                              </li>
                              <li>
                                <div className="radio-option">
                                  <input type="radio" name="type-group" id="payment-2" />
                                  <label for="payment-2">Despacho a domicilio</label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="default-title"><h2>Ingresa tus datos</h2></div>
                        <div className="row clearfix">
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <input type="text" name="field-name" value="" placeholder="Nombre" required/>
                          </div>
                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                              <input type="text" name="field-name" value="" placeholder="Telefono" required/>
                            </div>

                            <div className="form-group col-md-12 col-sm-12 col-xs-12">
                              <textarea placeholder="Dirección"></textarea>
                            </div>

                            <div className="form-group country col-md-12 col-sm-12 col-xs-12">
                              <label>Comuna</label>
                              <input type="text" value={comuna.nombre} disabled />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                          <div className="default-title"><h2>Enviar a otra dirección?</h2></div>
                          <div className="clearfix">
                            <div className="payment-options">
                              <ul>
                                <li>
                                  <div className="radio-option">
                                    <input type="radio" name="payment-group" id="payment-1" checked />
                                    <label for="payment-1">Av Sangueso 5564, Quilicura</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="radio-option">
                                    <input type="radio" name="payment-group" id="payment-2" />
                                    <label for="payment-2">Portal gato 44, Santiago</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>                             
                  </form>
                </div>
              </div>
              <div className="clearfix">
                {carrito.sort(sortProducto).map((v, index) => (
                  <CartItem key={v._id} {...v}
                    underline={index !== carrito.length - 1} />
                ))}
              </div>
              <div className="place-order">
                <div className="default-title"><h2>Metodo de pago</h2></div>
                <div className="payment-options">
                  <ul>
                    <li>
                      <div className="radio-option">
                        <input type="radio" name="payment-group" id="payment-1" checked/>
                          <label for="payment-1">Efectivo</label>
                      </div>
                    </li>
                    <li>
                      <div className="radio-option">
                        <input type="radio" name="payment-group" id="payment-2" />
                        <label for="payment-2">Tarjeta</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <button type="button" className="theme-btn btn-style-three place-order">Enviar Pedido!</button>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  carrito: carrito_from_state(state),
  comuna: comuna_from_state(state)
})

export default connect(mapStateToProps)(Checkout)