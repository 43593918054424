import React, { useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fijar_comuna_activa,  initial_setup } from "../actions";
import { int, is_empty } from '../utils';
import { MENU } from "../utils/Routes";


const ConfigurarComuna = ({ id_comuna, comunas, fijar_comuna_activa, initial_setup, history }) => {
  const [loading, setLoading] = useState(false)

  const _onChange = (e) => {
    fijar_comuna_activa(int(e.target.value))
  }

  const goBack = () => {
    setLoading(true)
    initial_setup().then(() => history.push(MENU))
      .catch(() => setLoading(false))
  }

  const disabled = is_empty(id_comuna) || loading

  return (
    <section className="news-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="sec-title-one">
                  <h2>Sushi Kamisushi</h2>
                  <h4>Selecciona tu comuna</h4>
                </div>
                <div className="form-box default-form wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <form onSubmit={goBack}>
                    <div className="form-group group-inner">
                      <select value={id_comuna} style={{ backgroundColor: 'white'}} onChange={_onChange}>
                        <option value="">----------------</option>
                        {comunas.map(v => (
                          <option key={v.id} value={v.id}>{v.nombre}</option>
                        ))}
                      </select>
                    </div>
                    <button className="theme-btn btn-style-three proceed-btn btn-block" disabled={disabled}>Continuar</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


const mapStateToProps = ({ comunas, session }) => ({
  id_comuna: session.id_comuna,
  comunas
})

const mapDispatachToProps = (dispatch)  => bindActionCreators({ 
  fijar_comuna_activa, initial_setup
}, dispatch)

export default connect(mapStateToProps, mapDispatachToProps)(ConfigurarComuna)