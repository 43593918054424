import { del, get, post } from "./Methods";


export const fetchComunas = () => get('/comunas') 
export const fetchSucursales = () => get('/sucursales')
export const fetchProductos = (sucursal) => get('/api/v1/productos/' + sucursal)
export const fetchCategorias = (sucursal) => get(`/api/v1/sucursales/${sucursal}/categorias`)
export const enviarCorreo = (email) => post('/api/email', { email })
export const login = (email, codigo) => post('/api/nori/login', { email, codigo })
export const logout = () => del('/api/v1/logout')
export const getSession = () => get('/api/v1/session')
export const fetchDirecciones = () => get('/api/v1/nori/usuario/direcciones')


export function isSuccess(res) {
  return res.status === 'success'
}