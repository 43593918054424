import React, { Component } from "react";
import { connect } from "react-redux";


class Sucursales extends Component {
  render() {
    return (
      <div>
{/*        <section className="page-title" style={{backgroundImage: 'url(/images/background/4.jpg)'}}>
          <div className="auto-container">
            <h3>Thanks For Visit Us</h3>
            <h2>We Make Stay Fresh Food & Wounderful View</h2>
          </div>
    </section>*/}
        <section className="news-section">
          <div className="auto-container">
            <div className="sec-title-one">
              <h2>Contactanos</h2>
              <h4>Nuestros Locales</h4>
            </div>
            <div className="row clearfix">
              {this.props.sucursales.map(sucursal => <Sucursal key={sucursal.id} { ...sucursal } />)}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const Sucursal = ({ id, nombre, thumbnail, direccion, telefonos }) => {
  const fijos = telefonos.filter(v => v.indexOf('+56') === -1)
  const mobil = telefonos.filter(v => v.indexOf('+56') !== -1)

  return (
    <div key={id} className="news-block col-md-4 col-sm-6 col-xs-12">
      <h2 class="text-center">{nombre}</h2>
      <div className="inner-box">
        <figure className="image-box">
          <img style={{ width: 370, height: 260 }} src={thumbnail !== null ? '/images/gallery/' + thumbnail : "/images/resource/branch-1.jpg"} alt="" />
        </figure>
        <div className="lower-content">
          <ul className="list-style-two">
            <li><span className="icon flaticon-arrows-6"></span> {direccion}</li>
            <li><span className="fa fa-whatsapp"></span> {mobil.map(v => <Telefono key={v} telefono={v} />)}</li>
            <li><span className="icon flaticon-technology"></span> {fijos.join(', ')}</li>
            <li><span className="icon flaticon-empty-email"></span> contacto@franquiciaskamisushi.cl</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const Telefono = ({ telefono }) => {
  const value = telefono.trim().replaceAll(' ', '')
  return <a href={`https://wa.me/${value}`} target="_blank">{telefono}</a>
}

const mapStateToProps = ({ sucursales }) => ({ sucursales })

export default connect(mapStateToProps)(Sucursales)