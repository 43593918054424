import React, { Component } from 'react'
import { Link } from "react-router-dom";

import { MENU } from "../utils/Routes";

export default class MainSlider extends Component {
  render() {
    return (
      <section className="main-slider" data-start-height="900" data-slide-overlay="yes">
        <div className="tp-banner-container">
          <div className="tp-banner">
            <ul>
              <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/image-1.jpg" data-saveperformance="off" data-title="Awesome Title Here">
                <img src="images/main-slider/image-1.jpg" alt="" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />

                <div className="tp-caption sfb sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="-140"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><figure className="content-image"><img src="images/main-slider/icon-1.png" alt="" /></figure></div>

                <div className="tp-caption sfl sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="-20"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><h2>Kamisushi Una Nueva experiencia</h2></div>

                <div className="tp-caption sfr sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="50"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><div className="text">Garantizamos la excelencia y el refinamiento de nuestros sushis siendo el<br /> escenario escogido por nuestros clientes.</div></div>

                <div className="tp-caption sfl sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="130"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><Link to={MENU} className="theme-btn btn-style-one">Menu</Link></div>

              </li>

              <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/image-3.jpg" data-saveperformance="off" data-title="Awesome Title Here">
                <img src="images/main-slider/image-2.jpg" alt="" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />

                <div className="tp-caption sfb sfb tp-resizeme"
                  data-x="left" data-hoffset="30"
                  data-y="center" data-voffset="-140"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><figure className="content-image"><img src="images/main-slider/icon-2.png" alt="" /></figure></div>

                <div className="tp-caption sfl sfb tp-resizeme"
                  data-x="left" data-hoffset="30"
                  data-y="center" data-voffset="-20"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><h2>Un placer en tu paladar</h2></div>

                <div className="tp-caption sfr sfb tp-resizeme"
                  data-x="left" data-hoffset="30"
                  data-y="center" data-voffset="50"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><div className="text text-left">Dejate conquistar por nuestros sabores. Utilizamos ingredientes frescos y de calidad</div></div>

                <div className="tp-caption sfl sfb tp-resizeme"
                  data-x="left" data-hoffset="30"
                  data-y="center" data-voffset="140"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><Link to={MENU} className="theme-btn btn-style-one">Menu</Link></div>

                <div className="tp-caption sfb sfb tp-resizeme"
                  data-x="right" data-hoffset="0"
                  data-y="center" data-voffset="-40"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><figure className="content-image"><img src="images/main-slider/content-image.png" alt="" /></figure></div>

              </li>

              <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/image-2.jpg" data-saveperformance="off" data-title="Awesome Title Here">
                <img src="images/main-slider/image-1.jpg" alt="" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />

                <div className="tp-caption sfb sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="-140"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><figure className="content-image"><img src="images/main-slider/icon-1.png" alt="" /></figure></div>

                <div className="tp-caption sfl sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="-20"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><h2>Nuestros Locales</h2></div>

                <div className="tp-caption sfr sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="50"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><div className="text">Disfruta donde quieras y cuando quieras</div></div>

                <div className="tp-caption sfl sfb tp-resizeme"
                  data-x="center" data-hoffset="0"
                  data-y="center" data-voffset="130"
                  data-speed="1500"
                  data-start="500"
                  data-easing="easeOutExpo"
                  data-splitin="none"
                  data-splitout="none"
                  data-elementdelay="0.01"
                  data-endelementdelay="0.3"
                  data-endspeed="1200"
                  data-endeasing="Power4.easeIn"><Link to={MENU} className="theme-btn btn-style-one">Menu</Link></div>

              </li>            

            </ul>

            <div className="tp-bannertimer"></div>
          </div>
        </div>
      </section>
    ) 
  }
}