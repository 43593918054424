const _w = window.$(window)

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

export function is_email(email) {
  return EMAIL_REG.test(email);
}

export function int(v) {
  return parseInt(v, 10)
}

export function window_width() {
  return _w.width();
}

export function is_empty(v) {
  return (!v || v === null || (typeof v === 'string' ? v.trim().length === 0 : false))
}

export function reduceProductos(t, v) {
  return t + (v.cantidad * v.price)
}