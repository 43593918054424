import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { clp } from '../utils/Numeral'
import { carrito_agregar } from "../actions";
import { is_empty } from '../utils';
import ComunaActual from '../components/ComunaActual';
import { productos_from_state } from '../reducers';



const Promos = ({ productos, carrito_agregar }) => (
  <div>
    {/*<img src="images/background/promo_sup.jpg" className="promos-sup" alt="promos" />*/}
    <section className="parallax-image-section" style={{ backgroundImage: 'url(images/background/principal_promo.jpg)' }}>
      <div className="auto-container">
        <h2>Elije tu promoción</h2>
        <h4>Promociones</h4>
      </div>
    </section>
    <section className="shop-section">
      <div className="auto-container">
        {/*<ComunaActual  />*/}
        <div className="row clearfix mt-3">
          {productos.sort(porPrecio).map(v => <Promo key={v._id} { ...v } agregar={carrito_agregar} />)}
        </div>
      </div>
    </section>
  </div>
)

function porPrecio(a, b) {
  return a.price - b.price
}

const Promo = ({ _id, title, price, detail, asset, agregar }) => (
  <div key={_id} className="dish-item col-md-4 col-sm-6 col-xs-12">
    <div className="inner-box">
      <figure className="image-box">
        <a onClick={() => agregar(_id)}><img style={{width: 370, height: 420}} src={asset !== '' ? "images/gallery" + asset : 'images/resource/dish-1.jpg'} alt="" /></a>
      </figure>
      <div className="lower-content">
        <h3 className="clearfix"><a href="">{title.toUpperCase()} <span className="pull-right">${clp(price)}</span></a></h3>
        <div className="text">{is_empty(detail) ? '--' : detail}</div>
      </div>
    </div>
  </div>
)

const mapStateToProps = (state) => {
  return {
    productos: productos_from_state(state).filter(v => v.tags === 'Promociones')
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ carrito_agregar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Promos)