import React, { Component } from 'react'
import { Link } from "react-router-dom";

import { MENU } from "../utils/Routes";
import MainSlider from '../components/MainSlider'

import init from "../Init";


export default class Home extends Component {
  componentDidMount() {
    init()
  }

  render() {
    return (
      <div>
        <MainSlider />
        <section className="restaurant" id="quienessomos">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-md-6 col-sm-6 col-xs-12">
                <div className="inner-box">
                  <div className="sec-title">
                    <h3>Descubre nuestro Restaurant</h3>
                  </div>
                  <div className="content">
                    <div className="text">
                      <p>Hoy o cualquier día es un buen día para deleitarse con nuestros platos, preparados con las mejores recetas tanto tradicionales como combinaciones modernas.Pruébalos, hoy es el día!</p>
                    </div>
                    <Link to={MENU} className="read-more theme-btn btn-style-one">Menu</Link>
                  </div>

                </div>
              </div>

              <div className="corousel-column col-md-6 col-sm-6 col-xs-12">
                <div className="carousel-outer wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div className="single-item-carousel">
                    <div className="slide">
                      <img src="images/resource/slide-1.jpg" alt="" />
                    </div>
                    <div className="slide">
                      <img src="images/resource/slide-2.jpg" alt="" />
                    </div>
                    <div className="slide">
                      <img src="images/resource/slide-3.jpg" alt="" />
                    </div>
                    <div className="slide">
                      <img src="images/resource/slide-4.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="parallax-image-section background1">
          <div className="auto-container">
            <h2>Deléitate con nuestros Kamisushis.</h2>
            <h4>Conoce nuestra carta de sushi y platos preparados.</h4>
          </div>
        </section>
        <section className="special-section">
          <div className="auto-container">
            <div className="sec-title-one">
              <h2>Promociones Especiales</h2>
              <h4>Pequeños precios para grandes sabores</h4>
            </div>
            <div className="row clearfix">
              <div className="opening-column col-md-4 col-sm-12 col-xs-12">
                <div className="inner-box wow slideInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div className="title-box"><img src="images/logo2.jpg" alt="logo" /></div>
                  <ul>
                    <li>ELIGE TU PROMOKAMI</li>
                    <li className="number">Promociones</li>
                  </ul>
                </div>
              </div>
              <div className="dishes-column col-md-8 col-sm-12 col-xs-12">
                <div className="inner-box">
                  <div className="special-dish">
                    <div className="inner-content">
                      <div className="row clearfix">
                        <div className="image-column col-md-4 col-sm-4 col-xs-12 pull-right">
                          <div className="inner">
                            <figure className="image-box">
                              <img src="images/resource/special-1.jpg" alt="" />
                              <div className="overlay-box">
                                <div className="price">$14.990</div>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div className="content-column col-md-8 col-sm-8 col-xs-12 pull-left">
                          <h3>PIEZAS SURTIDAS</h3>
                          <div className="text">70 PIEZAS SURTIDAS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="special-dish">
                    <div className="inner-content">
                      <div className="row clearfix">
                        <div className="image-column col-md-4 col-sm-4 col-xs-12 pull-left">
                          <div className="inner">
                            <figure className="image-box">
                              <img src="images/resource/special-2.jpg" alt="" />
                              <div className="overlay-box">
                                <div className="price">$11.500</div>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div className="content-column col-md-8 col-sm-8 col-xs-12 pull-right">
                          <h3>PIEZAS SURTIDAS</h3>
                          <div className="text">40 PIEZAS DE LA MEJOR SELECCIÓN DE SUSHI</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>
        <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fa fa-long-arrow-up"></span></div>
      </div>
    )
  }
}