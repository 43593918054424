const $ = window.$
const MasterSlider = window.MasterSlider
const noUiSlider = window.noUiSlider
const WOW = window.WOW

//Hide Loading Box (Preloader)
export function handlePreloader() {
  if ($('.preloader').length) {
    $('.preloader').delay(200).fadeOut(500);
  }
}

export function headerScrolling() {
  $(window).on('scroll', function () {
    headerStyle();
  });
}

export function headerStyle() {
  if ($('.main-header').length) {
    var windowpos = $(window).scrollTop();
    var siteHeader = $('.main-header');
    var scrollLink = $('.scroll-to-top');
    if (windowpos >= 200) {
      siteHeader.addClass('fixed-header');
      scrollLink.fadeIn(300);
    } else {
      siteHeader.removeClass('fixed-header');
      scrollLink.fadeOut(300);
    }
  }
}

//Hidden Bar Menu Config
export function hiddenBarMenuConfig() {
  var menuWrap = $('.hidden-bar .side-menu');
  // appending expander button
  menuWrap.find('.dropdown').children('a').append(function () {
    return '<button type="button" class="btn expander"><i class="icon fa fa-angle-down"></i></button>';
  });
  // hidding submenu 
  menuWrap.find('.dropdown').children('ul').hide();
  // toggling child ul
  menuWrap.find('.btn.expander').each(function () {
    $(this).on('click', function () {
      $(this).parent() // return parent of .btn.expander (a) 
        .parent() // return parent of a (li)
        .children('ul').slideToggle();

      // adding class to expander container
      $(this).parent().toggleClass('current');
      // toggling arrow of expander
      $(this).find('i').toggleClass('fa-angle-up fa-angle-down');

      return false;

    });
  });

  //Hidden Sidebar
  if ($('.hidden-bar').length) {
    var hiddenBar = $('.hidden-bar');
    var hiddenBarOpener = $('.hidden-bar-opener');
    var hiddenBarCloser = $('.hidden-bar-closer');
    $('.hidden-bar-wrapper').mCustomScrollbar();


    hiddenBarOpener.on('click', function () {
      hiddenBar.addClass('visible-sidebar');
    });


    hiddenBarCloser.on('click', function () {
      hiddenBar.removeClass('visible-sidebar');
    });
  }
}

export default function() {
  //Submenu Dropdown Toggle
  if ($('.main-header li.dropdown ul').length) {
    $('.main-header li.dropdown').append('<div class="dropdown-btn"></div>');

    //Dropdown Button
    $('.main-header li.dropdown .dropdown-btn').on('click', function () {
      $(this).prev('ul').slideToggle(500);
    });

    //Disable dropdown parent link
    $('.main-header .navigation li.dropdown > a,.hidden-bar .side-menu li.dropdown > a').on('click', function (e) {
      e.preventDefault();
    });
  }

  //Panels Toggle (Shopping Cart Page)
  if ($('.toggle-panel').length) {
    var targetPanel = $('.toggle-content');

    //Show Panel
    $('.toggle-panel').on('click', function () {
      $(this).toggleClass('active-panel');
      $(this).next(targetPanel).fadeToggle(300);
    });
  }


  //Revolution Slider
  if ($('.main-slider .tp-banner').length) {

    var MainSlider = $('.main-slider');
    var strtHeight = MainSlider.attr('data-start-height');
    var slideOverlay = "'" + MainSlider.attr('data-slide-overlay') + "'";

    $('.main-slider .tp-banner').show().revolution({
      dottedOverlay: slideOverlay,
      delay: 100000,
      startwidth: 1200,
      startheight: strtHeight,
      hideThumbs: 600,

      thumbWidth: 80,
      thumbHeight: 50,
      thumbAmount: 5,

      navigationType: "bullet",
      navigationArrows: "0",
      navigationStyle: "preview3",

      touchenabled: "on",
      onHoverStop: "off",

      swipe_velocity: 0.7,
      swipe_min_touches: 1,
      swipe_max_touches: 1,
      drag_block_vertical: false,

      parallax: "mouse",
      parallaxBgFreeze: "on",
      parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

      keyboardNavigation: "off",

      navigationHAlign: "center",
      navigationVAlign: "bottom",
      navigationHOffset: 0,
      navigationVOffset: 50,

      soloArrowLeftHalign: "left",
      soloArrowLeftValign: "center",
      soloArrowLeftHOffset: 20,
      soloArrowLeftVOffset: 0,

      soloArrowRightHalign: "right",
      soloArrowRightValign: "center",
      soloArrowRightHOffset: 20,
      soloArrowRightVOffset: 0,

      shadow: 0,
      fullWidth: "on",
      fullScreen: "off",

      spinner: "spinner4",

      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,

      shuffle: "off",

      autoHeight: "off",
      forceFullWidth: "on",

      hideThumbsOnMobile: "on",
      hideNavDelayOnMobile: 1500,
      hideBulletsOnMobile: "on",
      hideArrowsOnMobile: "on",
      hideThumbsUnderResolution: 0,

      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      startWithSlide: 0,
      videoJsPath: "",
      fullScreenOffsetContainer: ""
    });
  }

  //Single Item Slider
  if ($('.single-item-carousel').length) {
    $('.single-item-carousel').owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      smartSpeed: 700,
      autoplay: 4000,
      navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1200: {
          items: 1
        }
      }
    });
  }

  //Three Item Slider
  if ($('.three-item-corousel').length) {
    $('.three-item-corousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      smartSpeed: 700,
      autoplay: 4000,
      navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        800: {
          items: 2
        },
        1024: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });
  }

  //Five Item Slider
  if ($('.five-item-carousel').length) {
    $('.five-item-carousel').owlCarousel({
      loop: true,
      margin: 15,
      nav: true,
      smartSpeed: 700,
      autoplay: 4000,
      navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        800: {
          items: 3
        },
        1024: {
          items: 3
        },
        1200: {
          items: 4
        },
        1400: {
          items: 5
        }
      }
    });
  }

  //Tabs Box
  if ($('.tabs-box').length) {
    $('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {
      e.preventDefault();
      var target = $($(this).attr('data-tab'));

      if ($(target).is(':visible')) {
        return false;
      } else {
        target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
        $(this).addClass('active-btn');
        target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
        target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
        $(target).fadeIn(300);
        $(target).addClass('active-tab');
      }
    });
  }

  //Date Picker
  if ($('.datepicker').length) {
    $('.datepicker').datepicker();
  }

  //Gallery Popup Hide / Show
  if ($('.has-gallery-popup').length) {

    //Show Gallery Popup
    $('.has-gallery-popup').on('click', function (e) {
      e.preventDefault();
      var target = $($(this).attr('href'));
      $('body').addClass('popup-visible');
      $(target).addClass('now-visible');
    });

    //Show Gallery Popup
    $('.gallery-box .btn-close,.gallery-box .bg-fade-layer').on('click', function () {
      $('.gallery-box').removeClass('now-visible');
      $('body').removeClass('popup-visible');
    });

  }

  //Gallery Popup Slider / Vertical Gallery Slider
  if ($('.vertical-slider').length) {
    var slider = new MasterSlider();
    slider.setup('masterslider', {
      width: 850,
      height: 470,
      space: 10,
      view: 'basic',
      dir: 'v'
    });
    slider.control('arrows');
    slider.control('scrollbar', { dir: 'v' });
    slider.control('circletimer', { color: "#FFFFFF", stroke: 9 });
    slider.control('thumblist', { autohide: false, dir: 'v' });
  }

  //Price Range Slider
  if ($('.range-slider-price').length) {

    var priceRange = document.getElementById('range-slider-price');

    noUiSlider.create(priceRange, {
      start: [100, 300],
      limit: 500,
      behaviour: 'drag',
      connect: true,
      range: {
        'min': 50,
        'max': 500
      }
    });

    var limitFieldMin = document.getElementById('min-value-rangeslider');
    var limitFieldMax = document.getElementById('max-value-rangeslider');

    priceRange.noUiSlider.on('update', function (values, handle) {
      (handle ? limitFieldMax : limitFieldMin).value = values[handle];
    });
  }


  // Product Carousel Slider
  if ($('.shop-page .image-carousel').length && $('.shop-page .thumbs-carousel').length) {

    var $sync1 = $(".shop-page .image-carousel"),
      $sync2 = $(".shop-page .thumbs-carousel"),
      flag = false,
      duration = 500;

    $sync1
      .owlCarousel({
        loop: false,
        items: 1,
        margin: 0,
        nav: false,
        navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000
      })
      .on('changed.owl.carousel', function (e) {
        if (!flag) {
          flag = false;
          $sync2.trigger('to.owl.carousel', [e.item.index, duration, true]);
          flag = false;
        }
      });

    $sync2
      .owlCarousel({
        loop: false,
        margin: 10,
        items: 1,
        nav: false,
        navText: ['<span class="icon flaticon-arrows-8"></span>', '<span class="icon flaticon-arrows-9"></span>'],
        dots: false,
        center: false,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
          0: {
            items: 2,
            autoWidth: false
          },
          400: {
            items: 2,
            autoWidth: false
          },
          600: {
            items: 3,
            autoWidth: false
          },
          900: {
            items: 4,
            autoWidth: false
          },
          1000: {
            items: 4,
            autoWidth: false
          }
        },
      })

      .on('click', '.owl-item', function () {
        $sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);
      })
      .on('changed.owl.carousel', function (e) {
        if (!flag) {
          flag = true;
          $sync1.trigger('to.owl.carousel', [e.item.index, duration, true]);
          flag = false;
        }
      });

  }

  //Quantity Spinner
  if ($('.quantity-spinner').length) {
    $('.quantity-spinner .plus').on('click', function () {
      var val = $(this).prev('.prod_qty').val();
      $(this).prev('.prod_qty').val((val * 1) + 1);
    });
    $('.quantity-spinner .minus').on('click', function () {
      var val = $(this).next('.prod_qty').val();
      if (val != 1) {
        $(this).next('.prod_qty').val((val * 1) - 1);
      }
    });
  }

  //LightBox / Fancybox
  if ($('.lightbox-image').length) {
    $('.lightbox-image').fancybox({
      openEffect: 'fade',
      closeEffect: 'fade',
      helpers: {
        media: {}
      }
    });
  }

  //Contact Form Validation
  if ($('#contact-form').length) {
    $('#contact-form').validate({
      rules: {
        username: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true
        },
        website: {
          required: true
        },
        message: {
          required: true
        }
      }
    });
  }


  // Scroll to a Specific Div
  if ($('.scroll-to-target').length) {
    $(".scroll-to-target").on('click', function () {
      var target = $(this).attr('data-target');
      // animate
      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, 1500);

    });
  }


  // Elements Animation
  if ($('.wow').length) {
    var wow = new WOW(
      {
        boxClass: 'wow',      // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        offset: 0,          // distance to the element when triggering the animation (default is 0)
        mobile: true,       // trigger animations on mobile devices (default is true)
        live: true       // act on asynchronously loaded content (default is true)
      }
    );
    wow.init();
  }
}