import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from './components/Header'
import Footer from './components/Footer'
import { Home, Sucursales, Menu, Promos, Login, ShoppingCart, Checkout, ConfigurarComuna } from "./screens"
import { SUCURSALES, ROOT, MENU, PROMOS, LOGIN, SHOPPING_CART, CHECKOUT, SELECCIONAR_COMUNA, MENU_QR } from "./utils/Routes";
import { handlePreloader } from "./Init";
import { initial_setup } from "./actions";
import MenuQR from './screens/MenuQR';



class App extends Component {
  componentDidMount() {
    handlePreloader()
    this.props.initial_setup()
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path={MENU_QR} component={MenuQR} />
          <div className="page-wrapper">
            <div className="preloader"></div>
            <Route component={Header} />
            <Switch>
              <Route exact path={ROOT} component={Home} />
              <Route path={SUCURSALES} component={Sucursales} />
              <Route path={MENU} component={Menu} />
              <Route path={PROMOS} component={Promos} />
              <Route path={LOGIN} component={Login} />
              <Route path={SHOPPING_CART} component={ShoppingCart} />
              <Route path={CHECKOUT} component={Checkout} />
              <Route path={SELECCIONAR_COMUNA} component={ConfigurarComuna} />
            </Switch>
            <Route component={Footer} />
          </div>
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = ({  }) => ({
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  initial_setup
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App)