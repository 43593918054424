import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { SELECCIONAR_COMUNA } from '../utils/Routes'

import './ComunaActual.css'

const ComunaActual = ({ nombre }) => {
  return (
    <div className="cs-comuna">
      <h4>{nombre}</h4><Link to={SELECCIONAR_COMUNA}>Cambiar</Link>
    </div>
  )
}

const mapStateToProps =({ comunas, session }) => {
  const comuna = comunas.find(v => v.id === session.id_comuna)
  return {
    nombre: comuna ? comuna.nombre : '----------'
  }
}

export default connect(mapStateToProps)(ComunaActual)