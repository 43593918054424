import React, {  Component } from 'react'
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { enviarCorreo, isSuccess } from '../api';
import { ErrorMessage } from '../components/Alerts';
import { int, is_email, is_empty } from '../utils';
import { crear_session } from "../actions";


const responseGoogle = (response) => {
  console.log(response);
}

class Login extends Component {
  state =  {
    step: 0,
    email: '',
    codigo: '',
    error: '',
    loading: false
  }

  enviarCorreo = async (e) => {
    e.preventDefault()
    this.toggleLoading()
    this.clearError()
    try {
      const email = this.state.email.trim().toLowerCase()
      const res = await enviarCorreo(email)

      if (isSuccess(res))  {
        this.codeStep()
      } else {
        this.error(res.data)
      }
      console.log(res)
    } catch (e) {
      this.error(e)
    } finally {
      this.toggleLoading()
    }
  }

  login = async (e) => {
    e.preventDefault()
    this.toggleLoading()
    this.clearError()
    try {
      const email = this.state.email.trim().toLowerCase()
      const codigo = int(this.state.codigo)
      const res = await this.props.crear_session(email, codigo)

      console.log(res)
      if (isSuccess(res)) {
        this.props.history.goBack()
      } else {
        this.error(res.data)
      }
    } catch (e) {
      this.error(e)
    } finally {
      this.toggleLoading()
    }
  }

  error = (e) => {
    console.error(e)
    this.setState({ error: e.message ? e.message : e })
  }
  clearError = () => this.setState({ error: '' })

  onChange = (e) => this.setState({ [e.target.id]: e.target.value })
  codeStep = (v) => this.setState({ step: 1 })
  emailStep = (v) => this.setState({ step: 0 })
  toggleLoading = () => this.setState({ loading: !this.state.loading })

  render() {
    const {  email, codigo, step, error, loading } = this.state
    const disabledEmail = !is_email(email) || is_empty(email)
    const disabledCodigo = is_empty(codigo) || isNaN(codigo)
    const disabled = step === 0 ? disabledEmail : disabledCodigo

    return (
      <section className="news-section">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="sec-title-one">
                    <h2>Sushi Kamisushi</h2>
                    <h4>Ingresar</h4>
                      <p>
                        Puedes ingresar tu correo y te enviaremos un codigo con el cual puedes
                        ingresar para hacer tu pedido, también puedes usar el botón google para
                        ingresar
                      </p>
                  </div>
                  <div className="content">
                    <div className="text">
                    </div>
                  </div>
                  <div className="form-box default-form wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <form onSubmit={step === 0 ? this.enviarCorreo : this.login}>
                      <div className="clearfix">
                        <div className="form-group icon-group">
                          {step === 0 ?
                            <EmailForm email={email} onChange={this.onChange} /> :
                            <CodigoForm codigo={codigo} email={email} onChange={this.onChange} />}
                        </div>
                      </div>
                      <ErrorMessage value={error} />
                      <button className="theme-btn btn-style-three proceed-btn btn-block" disabled={disabled || loading}>{loading ? '...' : 'Continuar'}</button>
                      {step === 0 && <GoogleButton />}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const EmailForm = ({ email, onChange }) => (
  <div className="group-inner">
    <label>Correo electronico</label>
    <input id="email" value={email} type="text" placeholder="correo@electronico.com" onChange={onChange} />
  </div>
)

const CodigoForm = ({ email, codigo, onChange }) => (
  <div className="group-inner">
    <label>Ingresa el codigo</label>
    <input id="codigo" value={codigo} type="text" onChange={onChange} />
    <p>Código enviado a {email}</p>
  </div>
)

const GoogleButton = () => (
  <GoogleLogin
    className="btn-block"
    clientId="231222946548-7c2sp6jchdogdss7l2r1r55jeo8umk00.apps.googleusercontent.com"
    buttonText="Ingresa con tu cuenta google"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
)

const mapDispatachToProps = (dispatch)  => bindActionCreators({ crear_session }, dispatch)

export default connect(null, mapDispatachToProps)(Login)