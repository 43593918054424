import React, { Component, useState } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { is_empty } from '../utils';
import { clp } from '../utils/Numeral'
import { carrito_agregar } from "../actions";
import { Redirect } from 'react-router-dom';
import { SELECCIONAR_COMUNA } from '../utils/Routes';
import ComunaActual from '../components/ComunaActual';
import { productos_from_state, sucursal_from_state } from '../reducers';
import { fetchCategorias, isSuccess } from '../api';


const PAGE_SIZE = 12

class Menu extends Component {
  state = {
    categoria: '',
    categorias: [],
    filtro: ''
  }

  componentDidMount() {
    fetchCategorias(2).then(res => {
      if (isSuccess(res)) {
        const categorias = res.data
        const promos = categorias.find(v => v.nombre === 'Promociones')
        this.setState({ categorias, categoria: promos ? promos._id : '' })
      }
    })
  }

  fijarCategoria = (categoria) => this.setState({ categoria })
  filtroChange = (filtro) => this.setState({ filtro })

  filtrarCategoria = (v) => {
    let categoria = this.state.categorias.find(h => h._id === this.state.categoria)
    return categoria ? v.tags === categoria.nombre : false
  }
  
  filtrar = (v) => v.title.toLowerCase().indexOf(this.state.filtro.toLowerCase()) > -1

  render() {
    const { categoria, filtro, categorias } = this.state
    const productos = this.props.productos.filter(
      is_empty(filtro) ? this.filtrarCategoria : this.filtrar
    ).sort((a, b) => a.index - b.index)

    /*if (!this.props.sucursal) {
      return <Redirect to={SELECCIONAR_COMUNA} />
    }*/

    
    return (
      <div>
        <section className="parallax-image-section" style={{ backgroundImage: 'url(images/background/principal_Menu_01.jpg)' }}>
          <div className="auto-container">
            <h2>Siempre la mejor preparación</h2>
            <h4>Carta Menú</h4>
          </div>
        </section>
        <div className="auto-container">
          <ShopCart
            categorias={categorias}
            productos={productos}
            categoria={categoria}
            filtro={filtro}
            fijarCategoria={this.fijarCategoria}
            filtroChange={this.filtroChange}
            agregar={this.props.carrito_agregar} />
        </div>
      </div>
    )
  }
}



const ShopCart = ({ productos, categoria, filtro, fijarCategoria, filtroChange, agregar, categorias }) => {
  const [page, setPage ] =  useState(0)
  const length = productos.length
  const total = Math.ceil(length / PAGE_SIZE)
  const start = page * PAGE_SIZE
  const data = [...productos].splice(start, start + PAGE_SIZE)

  const onFijarCategoria = (v) => {
    fijarCategoria(v)
    setPage(0)
  }

  const onFiltro = (v) => {
    filtroChange(v)
    setPage(0)
  }

  return(
    <div className="sidebar-page-container left-side-bar">
      <div className="auto-container">
        {/*<ComunaActual />*/}
        <div className="row clearfix mt-2">
          <div className="content-side col-lg-9 col-md-8 col-sm-12 col-xs-12 pull-right">
            <div className="shop-item-section">
               <div className="items-sorting">
                <div className="row clearfix">
                  <div className="results-column col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <h4>Productos del 1 – {length} total de {length}</h4>
                  </div>
                </div>
              </div>
             	<div className="shop-items">
                <div className="row clearfix">
                  {data.map(v => <Producto key={v._id} {...v} agregar={agregar} />)} 
                </div>
              </div>
              <div className="styled-pagination text-center">
                <ul>
                  {[...Array(total).keys()].map(index => (
                    <li key={index}><a onClick={() => setPage(index)} className={index === page ? "active" : ""}>{index + 1}</a></li>
                  ))}
                  {total > 1 && <li><a className="next" onClick={() => setPage(page + 1)}>Siguiente</a></li>}
                </ul>
              </div>
            </div>
          </div>
          
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pull-left">
            <aside className="sidebar">
              <div className="sidebar-widget search-box">
                <form method="post" action="blog.html">
                  <div className="form-group">
                    <input type="search" value={filtro} onChange={e => onFiltro(e.target.value)} placeholder="Buscar producto" />
                    <button type="button"><span className="icon flaticon-magnifying-glass-1"></span></button>
                  </div>
                </form>
              </div>
              <div className="sidebar-widget recent-articles wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div className="sidebar-title"><h2>Categorias</h2></div>
                <ul className="list">
                  {categorias.sort(sortCategorias).map(v => (
                    <li key={v._id} ><a className={v._id === categoria ? "active" : ""} onClick={() => onFijarCategoria(v._id)}>{v.nombre}</a></li>
                  ))}
                </ul>
              </div>  
            </aside>
           </div>
        </div>
      </div>
    </div>
  )
}

const DEFAULT = "images/resource/dining-item-2.jpg"

const Producto = ({ _id, title, detail, price, asset, agregar }) => {
  const onClick = () => agregar(_id)

  return (
    <div key={_id} className="column col-md-6 col-sm-4 col-xs-4">
      <div className="food-item-two">
        <div className="inner-box">
          <div className="image-box">
            <a onClick={onClick}><img src={asset !== '' ? "images/gallery" + asset : DEFAULT} alt="" /></a>
          </div>
          <ul>
            <li className="clearfix"><span className="title-box"><a onClick={onClick}>{title}</a></span> <span className="dots">...................................</span> <span className="price">${clp(price)}</span></li>
            <li className="text">{detail}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function sortCategorias(a, b) {
  return a.nombre > b.nombre ? 1 : -1
}

const mapStateToProps = (state) => {
  const sucursal = sucursal_from_state(state)
  return {
    sucursal,
    productos: productos_from_state(state)
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ carrito_agregar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Menu)